import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SEO from '../../components/seo';
import Layout from '../../components/en';

const Tag = ({ pageContext }) => {
  const { posts, tagName, tagsEn } = pageContext;
  return (
    <Layout location={typeof window !== `undefined` ? location : null}>
      <SEO
        title="Furlan Blog - Stay up to date on everything Furlan Snowboards is doing"
        description="Events, videos, sessions, photos, riding with family and friends, we will talk about everything!"
        pathname="/en/tags/"
        keywords={[`snowboard`, `boards`, `versatility`]}
      />
      <div className="blogPage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 p-4">
              <h1>
                Blog : {tagName}
              </h1>
              {tagsEn &&
                tagsEn.map((tag) => {
                  const upperTag = tag.charAt(0).toUpperCase() + tag.slice(1);
                  return (
                    <Link key={tag} to={`/en/tags/${tag}`}>
                      <span className="badge badge-pill badge-primary badge-xl">
                        {upperTag}
                      </span>
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          {posts.map((post) => {
            const title = post.frontmatter.title || post.fields.slug;
            return (
              <div key={post.fields.slug} className="col-sm-6 col-lg-4 p-4">
                <Link to={post.frontmatter.path}>
                  <h2 className="mt-3">{title}</h2>
                </Link>
                <div className="mb-2">
                  {post.frontmatter.date}
                </div>
                <Link to={post.frontmatter.path}>
                  <p>{post.frontmatter.lead}</p>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Tag;

Tag.propTypes = {
  pageContext: PropTypes.shape({
    posts: PropTypes.array,
    tagName: PropTypes.string,
  }),
};
